import React, { useEffect } from 'react'
import { FLOCK_CAREERS_URL } from '@flock/flock-component-library'
import { useTracking } from '@flock/shared-ui'

// This page tracks if someone visits the careers page and also serves
// as an easy URL to give to people
const Careers = () => {
  const { trackPage } = useTracking()
  useEffect(() => {
    trackPage('careers', {
      category: 'landing',
    })
    window.location.href = FLOCK_CAREERS_URL
  }, [trackPage])
  return <div />
}

export default Careers
