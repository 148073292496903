export const PHONE_URL = 'tel:+17207039992'
export const PRETTY_PHONE_NUMBER = '(720) 703-9992'
export const PRETTY_PHONE_NUMBER_DANIELLE = '(720) 807-1711'
export const FLOCK_EMAIL = 'info@flockhomes.com'
export const FLOCK_HOMEPAGE_URL = 'https://www.flockhomes.com'
export const FLOCK_BLOG_URL = 'https://flockhomes.com/blog'

export const MOBILE_BREAKPOINT = '@media (max-width: 767px)'
export const TABLET_BREAKPOINT = '@media (max-width: 1200px)'
export const FLOCK_CAREERS_URL = 'https://boards.greenhouse.io/flockhomes'
